import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./config";
import { DASHBOARD_QR_CODE_TYPES, DYNAMIC_URLS } from "../helpers/constants";
import { REHYDRATE } from "redux-persist";

function invalidateOn({ success, error }) {
    return (result) => result ? success : error
}

function isHydrateAction(action) {
    return action.type === REHYDRATE
}

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['OverviewCodes', 'PublicID', 'GlobalSearch', 'PersonalQrCode', 'AnalyticsFilters', 'UserSettings', 'UserSubscription', 'PaymentMethods', 'UserNotifications'],
    extractRehydrationInfo(action) {
        if (isHydrateAction(action) && action.payload && action.payload[api.reducerPath]) {
            return action.payload[api.reducerPath]
        }
    },
    endpoints: builder => ({
        saveQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: 'core/qr-code/static/',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'AnalyticsFilters']
        }),
        getCodesList: builder.query({
            query: ({ activeTab, searchFilter, appliedSortFilter, appliedKindFilters, activePage, itemsPerPage }) => {
                return {
                    url: `/core/qr-code/?${activeTab === 'Archived' ? `&archived=true` : `&archived=false`}${appliedSortFilter ? `&sort=${appliedSortFilter}` : ''}${searchFilter !== '' ? `&name=${searchFilter}` : ''}${appliedKindFilters && appliedKindFilters.length > 0 ? appliedKindFilters.map((el) => `&kind=${DASHBOARD_QR_CODE_TYPES[el]}`).join('') : ''}${activePage ? `&page=${activePage}` : ''}&size=${itemsPerPage}`,
                    method: 'GET',
                }
            },
            providesTags: ['OverviewCodes']
        }),
        deleteStaticCode: builder.mutation({
            query: (id) => {
                return {
                    url: `/core/qr-code/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['OverviewCodes', 'GlobalSearch', 'AnalyticsFilters']
        }),
        createDynamicWebsiteQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/website-url/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        createDynamicAppQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/mobile-app/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'PublicID']
        }),
        createDynamicSocialMediaQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/social-media/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'PublicID']
        }),
        createPublicId: builder.query({
            query: (accessToken) => {
                return {
                    url: '/core/qr-code/utilities/generate_public_id',
                    method: 'POST',
                    body: {},
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                }
            },
            providesTags: ['PublicID']
        }),
        createDynamicImagesQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/dynamic/images/',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        uploadImagesDynamicImagesQrCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/images/${id}/images`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['PersonalQrCode']
        }),
        deleteImagesDynamicImagesQrCode: builder.mutation({
            query: ({ imageId, id }) => {
                return {
                    url: `/core/qr-code/dynamic/images/${id}/images/${imageId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['PersonalQrCode']
        }),
        createDynamicVideoQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/video/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        createDynamicProfileCardQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/profile-card/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'PublicID']
        }),
        createDynamicEventQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/event/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'PublicID']
        }),
        createDynamicMp3QrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/mp3/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        createDynamicPdfQrCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/pdf/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        createDynamicTextCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/text/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        createDynamicWifiCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/dynamic/wifi/`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PublicID', 'AnalyticsFilters'], error: [''] }),
        }),
        getCodeById: builder.query({
            query: (id) => {
                return {
                    url: `/core/qr-code/${id}`,
                    method: 'GET',
                }
            },
            providesTags: ['PersonalQrCode']
        }),
        handleArchiveStaticCode: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/static/${data.id}`,
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['OverviewCodes', 'GlobalSearch', 'AnalyticsFilters']
        }),
        handleArchiveDynamicCode: builder.mutation({
            query: (data) => {
                const formData = new FormData()

                formData.append('payload', JSON.stringify(data))

                return {
                    url: `/core/qr-code/dynamic/${DYNAMIC_URLS[`${data.data.kind}`]}/update/${data.id}`,
                    method: 'POST',
                    body: formData,
                }
            },
            invalidatesTags: ['OverviewCodes', 'GlobalSearch', 'AnalyticsFilters']
        }),
        handleConvertCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/static/${id}/convert_dynamic`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: ['OverviewCodes', 'PublicID', 'AnalyticsFilters']
        }),
        createSubscription: builder.mutation({
            query: ({ priceId, currency, country }) => {
                return {
                    url: `/core/subscriptions/`,
                    method: 'POST',
                    body: {
                        price_id: priceId,
                        currency,
                        country
                    }
                }
            }
        }),
        createAlternativeSubscription: builder.mutation({
            query: ({ price_id1, price_id2, currency, country }) => {
                return {
                    url: `/core/subscriptions/alternative`,
                    method: 'POST',
                    body: {
                        price_id1,
                        price_id2,
                        currency,
                        country
                    }
                }
            }
        }),
        getUserSubscription: builder.query({
            query: ({ fromEngine, isMobile, engineClickId, domainLanguage }) => {
                return {
                    url: `/core/subscriptions/me?new_flow=true${fromEngine ? `&traffic_source=${fromEngine}` : ''}&traffic_device=${isMobile ? 'mobile' : 'desktop'}${engineClickId ? `&google_clkid=${engineClickId}` : ''}${domainLanguage ? `&language=${domainLanguage}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['UserSubscription']
        }),
        getUserInvoices: builder.query({
            query: () => {
                return {
                    url: `/core/subscriptions/user-invoices`,
                    method: 'GET',
                }
            },
        }),
        getPublicCode: builder.query({
            query: (id) => {
                return {
                    url: `/core/qr-code/public/${id}`,
                    method: 'GET',
                }
            },
        }),
        getCodeStatistics: builder.query({
            query: ({ id, periodFilter, totalScans, userTimeZone }) => {
                return {
                    url: `/core/qr-code/stats/${id}?period=${periodFilter}&unique=${!totalScans}&user_timezone=${userTimeZone}`,
                    method: 'GET',
                }
            }
        }),
        globalSearch: builder.query({
            query: ({ searchValue, typeFilter, timeFilter, kindFilter }) => {
                return {
                    url: `/core/qr-code/search/?${searchValue ? `&name=${searchValue}` : ''}${searchValue ? timeFilter ? `&sort=${timeFilter === 'newest' ? 'newest_to_oldest' : 'oldest_to_newest'}` : '' : ''}${typeFilter !== 'All' ? `&type=${typeFilter.toLowerCase()}` : ''}${kindFilter?.length > 0 ? `&kind=${kindFilter.map((el) => DASHBOARD_QR_CODE_TYPES[el])}` : ''}`,
                    method: 'GET',
                }
            },
            providesTags: ['GlobalSearch']
        }),
        editDynamicWebsiteCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/website-url/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicPdfCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/pdf/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicMp3Code: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/mp3/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicVideoCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/video/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicImageCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/images/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicWifiCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/wifi/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        editDynamicTextCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/dynamic/text/update/${id}`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: invalidateOn({ success: ['OverviewCodes', 'PersonalQrCode'], error: [''] })
        }),
        manageSubscription: builder.mutation({
            query: (returnUrl) => {
                return {
                    url: `/core/subscriptions/manage`,
                    method: 'PUT',
                    body: {
                        return_url: returnUrl,
                    }
                }
            }
        }),
        getFiltersData: builder.query({
            query: () => {
                return {
                    url: `/core/qr-code/filters/`,
                    method: 'GET',
                }
            },
            providesTags: ['AnalyticsFilters']
        }),
        getAnalyticsData: builder.query({
            query: ({ unique, firstCode, secondCode, appliedFilters, dateFilter }) => {
                let preparedDateFormat = ''

                if (dateFilter !== 'All Time') {
                    const splittedDate = dateFilter.split(' - ')
                    const dateFromSplitted = splittedDate[0].split('/')
                    const dateToSplitted = splittedDate[1].split('/')
                    preparedDateFormat = `&date_from=${dateFromSplitted[2]}-${dateFromSplitted[0]}-${dateFromSplitted[1]}&date_to=${dateToSplitted[2]}-${dateToSplitted[0]}-${dateToSplitted[1]}`
                }

                return {
                    url: `/core/qr-code/analytics/?unique=${unique}${firstCode ? `&first_code=${firstCode.id}` : ''}${secondCode ? `&second_code=${secondCode.id}` : ''}${appliedFilters?.os?.length > 0 ? `&os=${appliedFilters.os.join(',')}` : ''}${appliedFilters?.browser?.length > 0 ? `&browser=${appliedFilters.browser.join(',')}` : ''}${appliedFilters?.country?.length > 0 ? `&country=${appliedFilters.country.join(',')}` : ''}${appliedFilters?.city?.length > 0 ? `&city=${appliedFilters.city.join(',')}` : ''}${appliedFilters?.language?.length > 0 ? `&language=${appliedFilters.language.join(',')}` : ''}${preparedDateFormat}`,
                    method: 'GET',
                }
            }
        }),
        getQRCodeByIdDirect: builder.mutation({
            query: (id) => {
                return {
                    url: `/core/qr-code/${id}`,
                    method: 'GET',
                }
            }
        }),
        pauseDynamicCode: builder.mutation({
            query: (data) => {
                const formData = new FormData()

                formData.append('payload', JSON.stringify(data))

                return {
                    url: `/core/qr-code/dynamic/${DYNAMIC_URLS[`${data.data.kind}`]}/update/${data.id}`,
                    method: 'POST',
                    body: formData,
                }
            },
            invalidatesTags: ['OverviewCodes', 'GlobalSearch', 'AnalyticsFilters']
        }),
        createQrCodeEvent: builder.mutation({
            query: (data) => {
                return {
                    url: `/core/qr-code/event/`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        sendEmailSendgrid: builder.mutation({
            query: () => {
                return {
                    url: `/core/qr-code/email/sendgrid-email`,
                    method: 'POST',
                    body: {},
                }
            },
        }),
        sendEmailSendgridAfterDownload: builder.mutation({
            query: (id) => {
                return {
                    url: `/core/qr-code/email/sendgrid-email/${id}/download`,
                    method: 'POST',
                    body: {},
                }
            },
        }),
        sendEmailSendgridScanCode: builder.mutation({
            query: ({ data, id }) => {
                return {
                    url: `/core/qr-code/email/sendgrid-email/scan/${id}`,
                    method: 'POST',
                    body: data,
                }
            }
        }),
        sendEmailSendgridAfterOpenCheckoutAndLeave: builder.mutation({
            query: () => {
                return {
                    url: `/core/qr-code/email/sendgrid-email/opened_and_not_paid`,
                    method: 'POST',
                    body: {},
                }
            }
        }),
        sendEmailSendgridContactUsEmail: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/contact_us',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        sendEmailSendgridAccountDeletionConfirmation: builder.mutation({
            query: () => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/account_deletion_confirmation',
                    method: 'POST',
                    body: {},
                }
            }
        }),
        sendEmailSendgridSorryToSeeYouGo: builder.mutation({
            query: () => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/sorry_to_see_you_go',
                    method: 'POST',
                    body: {},
                }
            }
        }),
        sendEmailSendgridUpdatesYourAccount: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/updates_your_account',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        sendEmailSendgridCancelPlan: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/cancel_plan',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        sendEmailSendgridPaymentMethodUpdates: builder.mutation({
            query: () => {
                return {
                    url: '/core/qr-code/email/sendgrid-email/payment_method_updated',
                    method: 'POST',
                    body: {},
                }
            }
        }),
        getUserSettings: builder.query({
            query: () => {
                return {
                    url: '/core/subscriptions/user-settings',
                    method: 'GET'
                }
            },
            providesTags: ['UserSettings']
        }),
        updateUserSettings: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/user-settings',
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['UserSettings']
        }),
        getPaymentMethods: builder.query({
            query: () => {
                return {
                    url: '/core/subscriptions/get-payment-methods',
                    method: 'GET'
                }
            },
            providesTags: ['PaymentMethods']
        }),
        addPaymentMethod: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/add-payment-method',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['PaymentMethods']
        }),
        setDefaultPaymentMethod: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/set-payment-method-as-default',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['UserSettings']
        }),
        updateProfileImage: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/update-image',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['UserSubscription']
        }),
        deletePaymentMethod: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/detach-payment-method',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['PaymentMethods', 'UserSettings']
        }),
        deleteUserProfile: builder.mutation({
            query: () => {
                return {
                    url: '/core/subscriptions/delete-user-profile',
                    method: 'DELETE',
                }
            }
        }),
        changeUserPlan: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/change-plan',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['UserSubscription']
        }),
        cancelSubscription: builder.mutation({
            query: () => {
                return {
                    url: '/core/subscriptions/cancel-subscription',
                    method: 'POST',
                    body: {},
                }
            },
            invalidatesTags: ['UserSubscription', 'UserSettings']
        }),
        createUserFeedback: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/dynamic/feedback/user-feedback',
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: ['UserSettings']
        }),
        getUserFeedbacks: builder.query({
            query: () => {
                return {
                    url: '/core/qr-code/dynamic/feedback/user-feedback',
                    method: 'GET'
                }
            }
        }),
        getOverviewCodeStats: builder.query({
            query: ({ id, unique }) => {
                return {
                    url: `/core/qr-code/overall-stats/${id}?unique=${unique}`,
                    method: 'GET',
                }
            },
            providesTags: ['OverviewCodes']
        }),
        exportScansData: builder.mutation({
            query: (format) => {
                return {
                    url: `/core/qr-code/export-scans-data/`,
                    method: 'POST',
                    body: {
                        format
                    },
                    responseHandler: (response) => response.blob()
                }
            }
        }),
        getUserNotifications: builder.query({
            query: (showAll) => {
                return {
                    url: `/core/subscriptions/notifications?read=${showAll}`,
                    method: 'GET',
                }
            },
            providesTags: ['UserNotifications']
        }),
        updateNotification: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/notifications',
                    method: 'PATCH',
                    body: data,
                }
            },
            invalidatesTags: ['UserNotifications', 'UserSettings']
        }),
        deleteNotification: builder.mutation({
            query: (id) => {
                return {
                    url: `/core/subscriptions/notifications`,
                    method: 'DELETE',
                    body: {
                        id,
                        read_all: false,
                    }
                }
            },
            invalidatesTags: ['UserNotifications', 'UserSettings']
        }),
        sendFeedbackOnDeleteAccount: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/qr-code/dynamic/feedback/user-feedback/leave',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        checkIfUserUsedTrial: builder.query({
            query: () => {
                return {
                    url: '/core/events/customer_paid_for_trial',
                    method: 'GET',
                }
            }
        }),
        facebookConversionPixel: builder.mutation({
            query: (data) => {
                return {
                    url: '/core/subscriptions/facebook-clickid',
                    method: 'POST',
                    body: data,
                }
            }
        }),
        renewSubscription: builder.mutation({
            query: () => {
                return {
                    url: '/core/subscriptions/renew-subscription',
                    method: 'POST',
                    body: {}
                }
            },
            invalidatesTags: ['UserSubscription', 'UserSettings']
        }),
        subscribeToSpecialYearlyOffer: builder.mutation({
            query: () => {
                return {
                    url: '/core/subscriptions/special-offer',
                    method: 'POST',
                    body: {
                        price_id_stripe: "0",
                        price_id_paypal: "0",
                        plan_name: "0"
                    }
                }
            },
            invalidatesTags: ['UserSubscription', 'UserSettings']
        }),
        getPaymentsDateInfo: builder.query({
            query: () => {
                return {
                    url: '/core/subscriptions/info',
                    method: 'GET',
                }
            }
        }),
        updateDuringCancelation: builder.mutation({
            query: () => {
                return {
                    url: '/core/subscriptions/update-during-cancellation',
                    method: 'POST',
                    body: {}
                }
            }
        })
    })
})

export const {
    useSaveQrCodeMutation,
    useGetCodesListQuery,
    useDeleteStaticCodeMutation,
    useCreateDynamicWebsiteQrCodeMutation,
    useCreateDynamicAppQrCodeMutation,
    useCreateDynamicSocialMediaQrCodeMutation,
    useCreatePublicIdQuery,
    useCreateDynamicImagesQrCodeMutation,
    useUploadImagesDynamicImagesQrCodeMutation,
    useCreateDynamicVideoQrCodeMutation,
    useCreateDynamicProfileCardQrCodeMutation,
    useCreateDynamicEventQrCodeMutation,
    useCreateDynamicMp3QrCodeMutation,
    useGetCodeByIdQuery,
    useHandleArchiveStaticCodeMutation,
    useHandleArchiveDynamicCodeMutation,
    useHandleConvertCodeMutation,
    useCreateSubscriptionMutation,
    useGetUserSubscriptionQuery,
    useGetUserInvoicesQuery,
    useGetPublicCodeQuery,
    useGetCodeStatisticsQuery,
    useCreateDynamicPdfQrCodeMutation,
    useGlobalSearchQuery,
    useEditDynamicWebsiteCodeMutation,
    useEditDynamicPdfCodeMutation,
    useEditDynamicMp3CodeMutation,
    useEditDynamicVideoCodeMutation,
    useEditDynamicImageCodeMutation,
    useEditDynamicWifiCodeMutation,
    useEditDynamicTextCodeMutation,
    useManageSubscriptionMutation,
    useDeleteImagesDynamicImagesQrCodeMutation,
    useCreateAlternativeSubscriptionMutation,
    useCreateDynamicTextCodeMutation,
    useCreateDynamicWifiCodeMutation,
    useGetFiltersDataQuery,
    useGetAnalyticsDataQuery,
    useGetQRCodeByIdDirectMutation,
    useCreateQrCodeEventMutation,
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
    useSendEmailSendgridMutation,
    useSendEmailSendgridAfterDownloadMutation,
    useSendEmailSendgridScanCodeMutation,
    useSendEmailSendgridAfterOpenCheckoutAndLeaveMutation,
    useSendEmailSendgridContactUsEmailMutation,
    useSendEmailSendgridAccountDeletionConfirmationMutation,
    useSendEmailSendgridSorryToSeeYouGoMutation,
    useSendEmailSendgridUpdatesYourAccountMutation,
    useSendEmailSendgridCancelPlanMutation,
    useSendEmailSendgridPaymentMethodUpdatesMutation,
    useGetPaymentMethodsQuery,
    useAddPaymentMethodMutation,
    useSetDefaultPaymentMethodMutation,
    useUpdateProfileImageMutation,
    useDeletePaymentMethodMutation,
    useDeleteUserProfileMutation,
    useChangeUserPlanMutation,
    useCancelSubscriptionMutation,
    useCreateUserFeedbackMutation,
    useGetUserFeedbacksQuery,
    useGetOverviewCodeStatsQuery,
    useExportScansDataMutation,
    useGetUserNotificationsQuery,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
    useSendFeedbackOnDeleteAccountMutation,
    useCheckIfUserUsedTrialQuery,
    useFacebookConversionPixelMutation,
    useRenewSubscriptionMutation,
    useSubscribeToSpecialYearlyOfferMutation,
    useGetPaymentsDateInfoQuery,
    useUpdateDuringCancelationMutation
} = api