import React, { useEffect, useRef, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useUserCodeCreationContext } from '../../../context/UserQrCreationContext';
import { useViewport } from '../../../hooks/useViewport';
import { isValidURL } from '../../../helpers/functions';

import CreationContentDescription from '../../common/CreationContentDescription/CreationContentDescription';
import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';
import CodeNameInput from '../CodeNameInput/CodeNameInput';

import activeFilterSvg from '../../../assets/icons/activefilter.svg'

const Link = ({ type, kind = 'static', triggerValidation, setIsValid }) => {
    const [error, setError] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [selectUrlType, setSelectUrlType] = useState('https://')
    const [showMobileFilter, setShowMobileFilter] = useState(false)
    const [linkValue, setLinkValue] = useState('')
    const { isMobile } = useViewport()

    const typeFilterRef = useRef()

    const { t } = useTranslation()
    const { setStaticValue, setDynamicWebsiteStyles, dynamicWebsiteStyles, isDynamic, staticValue } = useUserCodeCreationContext()

    const { register, getValues, setValue, trigger, resetField, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const handleChangeFilterType = async (url) => {
        const value = `${url}${getValues('link')}`

        setSelectUrlType(url)
        setShowMobileFilter(false)

        if (kind === "static" && getValues('link')) {
            setStaticValue(value)
        } else if (kind === "dynamic" && getValues('link')) {
            setDynamicWebsiteStyles({
                ...dynamicWebsiteStyles,
                website: value,
            })
        } else {
            setDynamicWebsiteStyles({
                ...dynamicWebsiteStyles,
                website: url,
            })
        }
    }

    const handleChangeUrl = async () => {
        let value = `${selectUrlType}${getValues('link')}`

        setLinkValue(value)
    }

    useEffect(() => {
        if (setIsValid) {
            setIsValid(isVerified)
        }
    }, [isVerified])

    useEffect(() => {
        if (isValidURL(linkValue)) {
            setError(false)
            setIsVerified(true)
            if (kind === "static") {
                setStaticValue(linkValue)
            } else {
                setDynamicWebsiteStyles({
                    ...dynamicWebsiteStyles,
                    website: linkValue,
                })
            }
        } else if (isVerified) {
            setError(true)
            setIsVerified(false)
        }

        const timer = setTimeout(() => {
            if (getValues('link')?.includes('https://') || getValues('link')?.includes('http://')) {
                const validValue = getValues('link').replace('https://', '').replace('http://', '')

                setValue('link', validValue)

                setLinkValue(`${selectUrlType}${validValue}`)
            }

            if (isValidURL(linkValue)) {
                setError(false)
                setIsVerified(true)
                if (kind === "static") {
                    setStaticValue(linkValue)
                } else {
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: linkValue,
                    })
                }
            } else if (!getValues('link')) {
                setError(false)
                setIsVerified(false)
                if (kind === "static") {
                    setStaticValue('')
                } else {
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: `${selectUrlType}`,
                    })
                }
            } else {
                setError(true)
                setIsVerified(false)
                if (kind === "static") {
                    setStaticValue('')
                } else {
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: `${selectUrlType}`,
                    })
                }
            }
        }, 1500)

        return () => {
            clearTimeout(timer)
        }
    }, [linkValue])

    useEffect(() => {
        resetField('link')
        setIsVerified(false)
        setError(false)
        setSelectUrlType('https://')
    }, [isDynamic, resetField])

    useEffect(() => {
        if (triggerValidation) {

            const triggerFunction = async () => {
                let result = false

                const value = `${selectUrlType}${getValues('link')}`

                if (isValidURL(value)) {
                    result = true
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        if (staticValue && !isDynamic && (staticValue.includes('https://') || staticValue.includes('http://'))) {
            setValue('link', staticValue?.split('://')[1])
            setSelectUrlType(staticValue?.split('://') ? staticValue?.split('://')[0] + '://' : 'https://')
            handleChangeUrl()
        } else {
            setValue('link', dynamicWebsiteStyles?.website?.split('://')[1])
            setSelectUrlType(dynamicWebsiteStyles?.website?.split('://') ? dynamicWebsiteStyles?.website?.split('://')[0] + '://' : 'https://')
            handleChangeUrl()
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (typeFilterRef.current && !typeFilterRef.current.contains(event.target)) {
                setShowMobileFilter(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container gap-0'>
            <div className='flex flex-col gap-y-6'>
                <CreationContentTitle title={type === 'authorized' ? t("urlDetails") : "URL"} withStep={type === 'authorized' ? false : true} />
                <CreationContentDescription
                    customStyles={{ marginTop: '-12px', marginBottom: '-8px' }}
                    description={t("enterOneUrlToLinkToYourQrCode")}
                />
                <CodeNameInput />
                <div className='content-creation-input-wrapper'>
                    <label className='content-creation-input-label'>{type === 'authorized' ? 'URL' : t("enterUrl")}</label>
                    <div className={(error || errors.link) ? 'url-input-wrapper-block url-input-wrapper-block-red-border' : isVerified ? 'url-input-wrapper-block url-input-wrapper-block-verified' : 'url-input-wrapper-block'}>
                        {isMobile ? (
                            <div onClick={() => setShowMobileFilter(true)} className='dropdown-label-wrapper-url-type'>
                                <MdKeyboardArrowDown size={28} fill="#141315" />
                                {selectUrlType}
                            </div>
                        ) : (
                            <Dropdown>
                                <Dropdown.Toggle style={{ color: '#141315' }} className='dropdown-label-wrapper-url-type' id="dropdown-basic">
                                    <MdKeyboardArrowDown size={28} fill="#141315" />
                                    {selectUrlType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className='bootstrap-default-item' onClick={() => handleChangeFilterType('https://')}>
                                        https://
                                    </Dropdown.Item>
                                    <Dropdown.Item className='bootstrap-default-item' onClick={() => handleChangeFilterType('http://')}>
                                        http://
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <input
                            className='form-input-dashboard creation-link-input border-radius-left-none'
                            style={{ height: '24px', paddingRight: '45px' }}
                            placeholder={t("enterWebsiteUrl")}
                            {...register("link", {
                                onChange: () => handleChangeUrl(),
                                value: staticValue?.split('://')[1],
                            })}
                        />
                        {isVerified && <BsFillCheckCircleFill className='icon-input-validation-passed' size={20} fill="#9747FF" />}
                    </div>
                    {(error || errors.link) && <p className='error-text'>{t("enterAValidUrl")}</p>}
                </div>
            </div>
            {showMobileFilter && (
                <div className='link-filter-background'>
                    <div ref={typeFilterRef} className='link-filter-block'>
                        <span className='link-filter-block-title'>website type</span>
                        <span className='link-filter-title-underline'></span>
                        <div onClick={() => handleChangeFilterType('https://')} className='link-filter-item'>
                            <span className={selectUrlType === 'https://' ? 'link-filter-active-item' : 'link-filter-default-item'}>https://</span>
                            {selectUrlType === 'https://' && (
                                <img src={activeFilterSvg} alt="" />
                            )}
                        </div>
                        <div onClick={() => handleChangeFilterType('http://')} className='link-filter-item'>
                            <span className={selectUrlType === 'http://' ? 'link-filter-active-item' : 'link-filter-default-item'}>http://</span>
                            {selectUrlType === 'http://' && (
                                <img src={activeFilterSvg} alt="" />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </form>
    )
}

export default Link