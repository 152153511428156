import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ImagePreview = ({ data }) => {
    const { t } = useTranslation()

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    const imagesMemo = useMemo(() => {
        if (data?.files?.length > 0) {
            return Array.from(data.files).map((image, idx) => (
                <div key={idx} className='image-preview-block-img-wrapper'>
                    <img className='image-preview-img' src={image.image ? image.image : URL.createObjectURL(image)} alt="" />
                </div>
            ))
        }
    }, [data.files])

    return (
        <div className='image-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
            <div className='image-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                <span className='image-previwe-title' style={{ color: data.designTextColor }}>{data.title ? data.title : t("albumTitle")}</span>
                <span className='image-preview-description' style={{ color: data.designTextColor }}>{data.description ? data.description : t("description")}</span>
                <button onClick={() => onClickRedirect(data.redirect)} className='image-preview-button' style={{ backgroundColor: data.designButtonColor }}>
                    {data.button ? data.button : 'CTA'}
                </button>
            </div>
            <div className='image-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                {data.files && data.files.length > 0 ? (
                    imagesMemo
                ) : (
                    <div className='image-preview-block-img-wrapper'></div>
                )}
            </div>
        </div>
    )
}

export default ImagePreview