import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import {
    api,
    useCreateAlternativeSubscriptionMutation,
    useCreateSubscriptionMutation
} from '../../api/api';
import {
    STRIPE_FIREBASE_EMAIL_LANGUAGE_TRANSLATIONS_KEYS,
    LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE,
    PRICE_SIGN_TRANSLATIONS
} from '../../helpers/translation-constants';
import { POWERED_BY_STRIPE_IMAGES_TRANSLATIONS } from '../../helpers/image-translations';
import useAccountNewPlans from '../../hooks/useAccountNewPlans';
import { useAuthContext } from '../../context/AuthContext';
import useGenerateUrl from '../../hooks/useGenerateUrl';
import { accountPlans } from '../../helpers/constants';
import { useViewport } from '../../hooks/useViewport';
import i18n from '../../i18n/config';

import DashboardNavigation from '../../components/DashboardNavigation/DashboardNavigation';
import StripeForm from '../../components/StripeForm/StripeForm';

import CheckoutCancelAnytimeGreen from '../../assets/icons/checkout-cancel-anytime-green.svg'
import CheckoutMoneyBackGreen from '../../assets/icons/checkout-money-back-green.svg'
import checkoutCancelAnytime from '../../assets/icons/checkout-cancel-anytime.svg'
import CheckoutDesktopBack from '../../assets/icons/checkout-desktop-back.svg'
import checkoutCreditCard from '../../assets/icons/cehckout-credit-card.svg'
import CheckoutStepActive from '../../assets/icons/checkout-step-active.svg'
import checkoutMoneyBack from '../../assets/icons/checkout-money-back.svg'
import CheckoutStepDone from '../../assets/icons/checkout-step-done.svg'
import poweredByStripe from '../../assets/icons/powered-by-stripe.png'
import checkoutPaypal from '../../assets/icons/checkout-paypal.svg'

const CheckoutForm = () => {
    const stripePromise = useMemo(() => {
        return loadStripe("pk_live_51NjCF0G2gC76wG4CuacboIvtDia4Pv87gp8SF7Xi2ue6gvD6kdr4BKpb1btzbgfW9d2aTXvMzSIrQQzz8PppRKDW00YBZRKLCH", {
            locale: STRIPE_FIREBASE_EMAIL_LANGUAGE_TRANSLATIONS_KEYS[i18n.language],
        });
    }, [i18n.language]);

    const subscription = useSelector((state) => state.app.subscription)
    const [secret, setSecret] = useState('')
    const [planPrice, setPlanPrice] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [searchParams] = useSearchParams()
    const { secret: priceId, plan } = useParams()

    const clientSecret = secret ? secret : ''

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isMobile } = useViewport()
    const generateUrl = useGenerateUrl()
    const { accountNewPlans } = useAccountNewPlans()
    const { setExpandNavigation } = useAuthContext()

    const [createSubscription] = useCreateSubscriptionMutation()
    const [createAlternativeSubscription] = useCreateAlternativeSubscriptionMutation()

    const appearance = {
        theme: 'none',
        variables: {
            spacingUnit: '4px',
        },
        rules: {
            '.Tab': {
                border: '1px solid #E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
            },

            '.Input': {
                border: '1px solid #E2E8F0',
                backgroundColor: '#F7FAFE',
                padding: '14px 16px',
                outline: 'none',
                marginBottom: '3px'
            },

            '.Label': {
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '18px',
                color: '#334155',
                fontVariant: 'normal',
                marginBottom: '3px'
            },

            '.Tab:hover': {
                color: 'var(--colorText)',
            },

            '.Tab--selected': {
                borderColor: '#E2E8F0',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
            },

            '.Input::placeholder': {
                color: '#7D8898',
                fontSize: '16px',
            },

            '.TermsText': {
                color: '#7D8898',
                fontSize: '12px',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
    };

    useEffect(() => {
        setExpandNavigation(false)
    }, [setExpandNavigation])

    useEffect(() => {
        if (plan) {
            setSelectedPlan(accountNewPlans.find((el) => el.shortName === plan))
        }
    }, [plan, accountNewPlans])

    useEffect(() => {
        const paymentTime = localStorage.getItem('paymentTime')

        if (paymentTime && new Date() - new Date(paymentTime) < 1000000) {
            dispatch(api.util.invalidateTags(['UserSubscription']))
            navigate('/dashboard')
        } else if (paymentTime && new Date() - new Date(paymentTime) > 1000000) {
            localStorage.removeItem('paymentTime')
        }
    }, [navigate, dispatch])

    useEffect(() => {
        (async () => {
            let res;

            if (priceId.includes(',')) {
                const [price_id1, price_id2] = priceId.split(',')

                res = await createAlternativeSubscription({
                    price_id1,
                    price_id2,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            } else if (priceId === 'price_1OFbqAG2gC76wG4CDgASNzV8') {
                res = await createAlternativeSubscription({
                    price_id1: priceId,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            } else {
                res = await createSubscription({
                    priceId,
                    currency: LANGUAGE_SHORT_VERSION_TO_CURRENCY_CODE[i18n.language] ?? 'USD',
                    country: i18n.language
                })
            }

            setSecret(res?.data?.client_secret)
        })()
    }, [createSubscription, createAlternativeSubscription, priceId])

    useEffect(() => {
        let foundPlanPrice;

        if (subscription?.new_flow) {
            foundPlanPrice = accountNewPlans.find((el) => el.shortName === plan)?.yearlyPrice[i18n.language]
                ?? accountNewPlans.find((el) => el.shortName === plan)?.yearlyPrice['en']
        } else {
            foundPlanPrice = accountPlans.find((el) => el.shortName === plan)?.yearlyPrice
        }

        if (foundPlanPrice) {
            setPlanPrice(foundPlanPrice)
        }
    }, [plan])

    return (
        <div
            style={{
                paddingTop: '0px',
                paddingLeft: searchParams.get('isNewUser') === 'true' && !isMobile ? '8px' : '0px',
                paddingBottom: '0px'
            }}
            className='purple-corners-block-wrapper'
        >
            {searchParams.get('isNewUser') !== 'true' && (
                <DashboardNavigation setOverviewCode={() => { }} />
            )}
            {clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                    <StripeForm priceId={priceId} planName={plan} />
                </Elements>
            ) : (
                <div className="checkout-page-loader-wrapper">
                    <div className="checkout-page-loader h-full pb-4">
                        <div className='flex items-center justify-between w-full max-w-[1264px] mt-[40px] mobile:hidden'>
                            <div className='flex items-center gap-[16px]'>
                                <div className='min-w-[40px] h-[40px] rounded-[8px] border-solid border-[1px] border-[#E2E8F0] flex items-center justify-center cursor-pointer'>
                                    <img src={CheckoutDesktopBack} alt="" />
                                </div>
                                <div className="checkout-body-header text-[#141315]">{t("secureCheckout")}</div>
                                <img
                                    src={
                                        POWERED_BY_STRIPE_IMAGES_TRANSLATIONS[i18n.language]
                                        ?? POWERED_BY_STRIPE_IMAGES_TRANSLATIONS['en']
                                    }
                                    width={120}
                                    alt=""
                                />
                            </div>
                            <div className='upgrade-plan-new-plan-header__steps'>
                                <div className='upgrade-plan-new-plan-header__step-item'>
                                    <img src={CheckoutStepDone} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-text'>
                                        {t("qrCodeReady")}
                                    </span>
                                </div>
                                <div className='upgrade-plan-new-plan-header__step-item'>
                                    <img src={CheckoutStepDone} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-text'>
                                        {t("selectAPlan")}
                                    </span>
                                </div>
                                <div className='upgrade-plan-new-plan-header__step-item-active'>
                                    <img src={CheckoutStepActive} alt="" />
                                    <span className='upgrade-plan-new-plan-header__step-item-text'>
                                        {t("completed")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-body h-full">
                            <div className="checkout-field-block stripe-shadow-blocks h-fit">
                                <div className="checkout-card-field pt-0">
                                    <span className="checkout-new-payment-method-title">
                                        {t("paymentMethod")}
                                    </span>
                                    <div className='select-payments-block-wrapper'>
                                        <div className={`selecet-payment-method-element active`}>
                                            <img src={checkoutCreditCard} alt="" />
                                            <span className='select-payment-method-name'>{t("creditCard")}</span>
                                        </div>
                                        <div className={`selecet-payment-method-element`}>
                                            <img src={checkoutPaypal} alt="" />
                                            <span className='select-payment-method-name'>PayPal</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='preparing-payment-information-block'>
                                    <svg className='stripe-loader' viewBox="25 25 50 50">
                                        <circle r="20" cy="50" cx="50"></circle>
                                    </svg>
                                    {t("preparingPaymentInformation")}
                                </div>
                            </div>
                            <div className="checkout-body-right border-solid border-[1px] border-[#E2E8F0] stripe-shadow-blocks">
                                <div className='checkout-guarantee-block disable-on-desktop'>
                                    <span style={{ color: '#7D8898' }} className='new-flow-checkout-text'>{t("byClickingPurchaseYouAgreeToBeCharged")} {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice} {t("nowAndAcceptOur")} <Link className='new-flow-checkout-text-link' to={generateUrl('/terms-of-service')}>{t("termsOfUse")}</Link> {t("and")} <Link className='new-flow-checkout-text-link' to={generateUrl("/privacy-policy")}>{t("privacyPolicyWithDot")}</Link> {t("yourPaymentWillAppearAsQRCodeDeveloperOnYourCard")} {plan !== 'Yearly' ? t("after14daysYouWillBeBilledEveryMonth") : ""} {t("youCanCancelAnytime")} </span>
                                </div>
                                <div className='checkout-guarantee-underline disable-on-desktop'></div>
                                <div className='checkout-guarantee-block'>
                                    <div className='checkout-guarantee-icon-wrapper border-0'>
                                        <img src={CheckoutMoneyBackGreen} alt="" />
                                    </div>
                                    <span className='checkout-guarantee-block-title'>{t("moneyBackGuarantee")}</span>
                                    <span className='checkout-guarantee-block-text'>{t("ifYouAreNotFullySatisfiedWithinThe14day")}</span>
                                </div>
                                <div className='checkout-guarantee-underline'></div>
                                <div className='checkout-guarantee-block'>
                                    <div className='checkout-guarantee-icon-wrapper border-0'>
                                        <img src={CheckoutCancelAnytimeGreen} alt="" />
                                    </div>
                                    <span className='checkout-guarantee-block-title'>{t("cancelViaYOurOnlineAccount")}</span>
                                    <span className='checkout-guarantee-block-text'>{t("youMayCancelAnytimeFromDashboard")}</span>
                                </div>
                                <div style={{ marginBottom: '116px' }} className='checkout-guarantee-underline disable-on-desktop'></div>
                            </div>
                            <div className='change-selected-plan-block-mobile'>
                                <div className='change-selected-plan-block-info'>
                                    <span className='change-selected-plan-block-title'>{t("selectedPlan")}</span>
                                    <span className='change-selected-plan-block-name'>{selectedPlan?.name}</span>
                                </div>
                                <span className='change-selected-plan-block-change-btn'>{t("changePlan")}</span>
                            </div>
                            <div className='checkout-new-purchase-block'>
                                <div className='checkout-new-due-total-block w-full'>
                                    <span className='checkout-new-due-total-title'>{t("dueToday")}</span>
                                    <span className='checkout-new-due-total-price'>
                                        {PRICE_SIGN_TRANSLATIONS[i18n.language] ?? '$'}{planPrice}
                                    </span>
                                </div>
                                <button
                                    id="submit"
                                    className="checkout-new-purchase-button relative"
                                >
                                    {t("purchase")}
                                </button>
                            </div>
                        </div>
                    </div>
                    {searchParams.get('isNewUser') !== 'true' && !isMobile && (
                        <div className="upgrade-plan-navigation-line">
                            <div className='upgrade-plan-navigation-line-top'>
                                <div className='upgrade-plan-navigation-line-top-inside'></div>
                            </div>
                            <div className='upgrade-plan-navigation-line-bottom'>
                                <div className='upgrade-plan-navigation-line-bottom-inside'></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CheckoutForm