import React from 'react'
import { useTranslation } from 'react-i18next'

const PdfPreview = ({ data }) => {
    const { t } = useTranslation()

    const handleOpenFiles = (files) => {
        if (files && files.length > 0) {
            files.forEach(async (file) => {
                const response = await fetch(file.file);
                const blob = await response.blob();

                const decodedBlob = new Blob([blob], { type: 'application/pdf' });

                const url = URL.createObjectURL(decodedBlob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'document.pdf';
                link.click();

                window.URL.revokeObjectURL(url);
            })
        }
    }

    return (
        <div className='pdf-preview-wrapper'>
            <div className='pdf-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                <div className='pdf-logo-block'>
                    {data.logo ? (
                        <img className='pdf-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt='' />
                    ) : (
                        <div className='pdf-logo-empty'></div>
                    )}
                </div>
                <span className='pdf-company-name' style={{ color: data.designTextColor }}>{data.company ? data.company : t("companyName")}</span>
                <h5 className='pdf-title-text' style={{ color: data.designTextColor }}>{data.title ? data.title : t("pdfTitle")}</h5>
                <span className='pdf-company-description' style={{ color: data.designTextColor }}>{data.description ? data.description : t("description")}</span>
                <button onClick={() => handleOpenFiles(data.files)} className='pdf-button' style={{ backgroundColor: data.designButtonColor }}>
                    {data.button ? data.button : 'CTA'}
                </button>
            </div>
            <div className='pdf-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                <div className='pdf-image-block'>
                    {data.image && (
                        <img className='pdf-preview-img' src={typeof (data.image) === "string" ? data.image : URL.createObjectURL(data.image)} alt="" />
                    )}
                </div>
            </div>
        </div >
    )
}

export default PdfPreview