import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, OverlayTrigger } from 'react-bootstrap'
import { HiOutlinePencil } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { USER_QR_TYPE_TRANSLATIONS } from '../../helpers/translation-constants'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import useReturnCodeSvg from '../../hooks/useReturnCodeSvg'
import { CODE_TYPE_TO_NAME } from '../../helpers/constants'

import DashboardCodeTrend from './DashboardCodeTrend'

import dashboardNavigationDownload from '../../assets/icons/dashboard-navigation-download.svg'
import dashboardMoreOptions from '../../assets/icons/dashboard-more-options.svg'
import dashboardEditPencil from '../../assets/icons/dashboard-edit-pencil.svg'
import dashboardDownload from '../../assets/icons/dashboard-download.svg'
import dashboardDelete from '../../assets/icons/dashboard-delete.svg'
import dashboardPause from '../../assets/icons/dashboard-pause.svg'
import confirmArchive from '../../assets/icons/confirm-archive.svg'
import confirmDelete from '../../assets/icons/confirm-delete.svg'

const CodeBlock = ({
    view,
    code,
    trialDaysLeft,
    handleClickOnCode,
    handleArchiveCode,
    handleDeleteCode,
    downloadCode,
    handleArchiveDynamicCode,
    handleConvertData,
    setArchiveId,
    setDeleteId,
    handleArchiveCodeConfirmed,
    handleDeleteCodeConfirmed,
    archiveId,
    isMobile,
}) => {
    const [codeStatus, setCodeStatus] = useState('Active')
    const [selectedCodeOptions, setSelectedCodeOptions] = useState(null)
    const [showMobileArchiveConfirm, setShowMobileArchiveConfirm] = useState(false)
    const [showMobileDeleteConfirm, setShowMobileDeleteConfirm] = useState(false)
    const [hideOptions, setHideOptions] = useState(false)
    const subscription = useSelector((state) => state.app.subscription)

    const { dynamicBlockIcons } = useReturnCodeSvg()
    const { t } = useTranslation()

    const navigate = useCustomNavigate()

    const mobileCodeOptionsRef = useRef(null)

    const handleOpenOverviewPanel = (event, code) => {
        if (view === 'Grid') {
            if (event.target.closest('.dashboard-grid-view-bottom-right-block') === null) {
                handleClickOnCode(code)
            }
        } else {
            if (event.target.closest('.disable-overview-click') === null) {
                handleClickOnCode(code)
            }
        }
    }

    const handleOpenMobileOptions = (code) => {
        setSelectedCodeOptions(code)
    }

    const handleNavigateToUpgrade = () => {
        if (subscription?.new_flow) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
    }

    const handleToggleDropdown = (status) => {
        setHideOptions(status)
    }

    useEffect(() => {
        if (code.type === 'dynamic' && trialDaysLeft > 0 && (code.archived || code.data.paused)) {
            setCodeStatus('Paused')
        } else if (code.type === 'dynamic' && trialDaysLeft <= 0) {
            setCodeStatus('Expired')
        } else if (code.type === 'dynamic' && (!code.archived && !code.data.paused && trialDaysLeft > 0)) {
            setCodeStatus('Active')
        } else {
            setCodeStatus('Static')
        }
    }, [code, trialDaysLeft])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileCodeOptionsRef.current && !mobileCodeOptionsRef.current.contains(event.target)) {
                setSelectedCodeOptions(null)
                setShowMobileArchiveConfirm(false)
                setShowMobileDeleteConfirm(false)
                setArchiveId(null)
                setDeleteId(null)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [setArchiveId, setDeleteId])

    return (
        <>
            {view === 'List' ? (
                <>
                    {isMobile ? (
                        <div className='dashboard-list-view-mobile-element'>
                            <div onClick={() => handleClickOnCode(code)} className='dashboard-list-view-mobile-left-side'>
                                <div className='dashboard-list-view-mobile-left-name'>
                                    <span>
                                        {code.name}
                                    </span>
                                    {code.type === 'dynamic' && !code.archived && !code.data.paused && trialDaysLeft > 0 && (
                                        <div className='dashboard-active-dynamic-code-green-circle-wrapper'>
                                            <div className='dashboard-active-dynamic-code-green-circle'></div>
                                        </div>
                                    )}
                                </div>
                                <div className='dashboard-list-view-mobile-left-status-blocks'>
                                    {trialDaysLeft > 0 ? (
                                        <>
                                            {code.type === 'dynamic' ? (
                                                <div className='dashboard-grid-view-content-type-dynamic-block'>{t("dynamic")}</div>
                                            ) : (
                                                <div className='dashboard-grid-view-code-type-block'>{t("static")}</div>
                                            )}
                                            <div className='dashboard-grid-view-code-type-block'>
                                                <img className='dashboard-grid-view-code-type-icon' width={12} src={dynamicBlockIcons[code.data.kind]} alt="" />
                                                {t(`${USER_QR_TYPE_TRANSLATIONS[CODE_TYPE_TO_NAME[code.data.kind]]}`)}
                                            </div>
                                        </>
                                    ) : (
                                        <div className='dashboard-view-grid-code-expired'>{t("expired")}</div>
                                    )}
                                </div>
                            </div>
                            <div className='dashboard-list-view-mobile-right-side'>
                                <div>
                                    {trialDaysLeft > 0 ? (
                                        <>
                                            {code.type === 'static' && !code.archived && (
                                                <div onClick={() => handleConvertData(code)} className='dashboard-grid-view-blue-button'>{t("convert")}</div>
                                            )}
                                            {code.data.paused && (
                                                <div onClick={() => handleArchiveCode(code)} className='dashboard-grid-view-blue-button'>{t("resume")}</div>
                                            )}
                                        </>
                                    ) : (
                                        <div onClick={handleNavigateToUpgrade} className='dashboard-grid-view-button-gradient'>{t("upgrade")}</div>
                                    )}
                                </div>
                                <div onClick={() => handleOpenMobileOptions(code)} className='dashboard-options-icon-wrapper'>
                                    <img src={dashboardMoreOptions} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <tr style={{ width: '100%', overflow: 'hidden' }} onClick={(e) => handleOpenOverviewPanel(e, code)}>
                            <td style={{ maxWidth: '150px', paddingLeft: '8px' }}>
                                <div style={{ maxWidth: '420px' }} className='code-block-name'>
                                    <img className={`code-block-fill-type-icon ${codeStatus.toLowerCase()}`} width={16} src={dynamicBlockIcons[code.data.kind]} alt="" />
                                    {code.name}
                                </div>
                            </td>
                            <td>{t(`${USER_QR_TYPE_TRANSLATIONS[CODE_TYPE_TO_NAME[code.data.kind]]}`)}</td>
                            <td>{code.type === 'dynamic' ? t("dynamic") : t("static")}</td>
                            <td className={`dashboard-list-view-scans-count ${code.archived || code.data.paused ? 'inactive' : ''}`}>{code.type === 'dynamic' ? code.data.scans : '--'}</td>
                            <td>
                                {codeStatus === 'Paused' ? (
                                    <div className='dashboard-list-view-status-content disable-overview-click'>
                                        {t("paused")}
                                        <div onClick={() => handleArchiveCode(code)} className='dashboard-grid-view-blue-button'>{t("resume")}</div>
                                    </div>
                                ) : codeStatus === 'Expired' ? (
                                    <div className='dashboard-list-view-status-content disable-overview-click'>
                                        {t("paused")}
                                        <div onClick={handleNavigateToUpgrade} className='dashboard-grid-view-button-gradient'>{t("upgrade")}</div>
                                    </div>
                                ) : codeStatus === 'Active' ? (
                                    <div className='dashboard-list-view-element-active-text'>
                                        {t("active")}
                                        <div className='dashboard-active-dynamic-code-green-circle'></div>
                                    </div>
                                ) : (
                                    <div onClick={() => handleConvertData(code)} className='dashboard-grid-view-blue-button disable-overview-click'>
                                        {t("convertToDynamic")}
                                    </div>
                                )}
                            </td>
                            <td width={186}>
                                {code.type === 'dynamic' && (
                                    <DashboardCodeTrend
                                        code={code}
                                        trialDaysLeft={trialDaysLeft}
                                    />
                                )}
                                <div className='dashboard-list-view-hover-options'>
                                    <div className='dashboard-options-icons-block disable-overview-click'>
                                        {code.type === 'static' ? (
                                            <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<div className='tooltip-custom'>{t("download")}</div>}
                                                >
                                                    {trialDaysLeft <= 0 ? (
                                                        <div className='dashboard-options-icon-wrapper disabled'>
                                                            <img src={dashboardDownload} alt="" />
                                                        </div>
                                                    ) : (
                                                        <div onClick={() => downloadCode(code)} className='dashboard-options-icon-wrapper'>
                                                            <img src={dashboardDownload} alt="" />
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                                <Dropdown onToggle={handleToggleDropdown}>
                                                    <Dropdown.Toggle disabled={trialDaysLeft <= 0 ? true : false} className='bootstrap-default-dropdown bootstrap-transparent-dropdown dropdown-disabled' id="dropdown-basic">
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={!hideOptions ? <div className='tooltip-custom'>{t("options")}</div> : <></>}
                                                        >
                                                            <div className='dashboard-options-icon-wrapper'>
                                                                <img src={dashboardMoreOptions} alt="" />
                                                            </div>
                                                        </OverlayTrigger>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='dashboard-options-menu-block'>
                                                        <Dropdown.Item onClick={() => handleDeleteCode(code.id)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                            <div className='dashboard-more-options-item-wrapper'>
                                                                {t("deleteCode")}
                                                                <img src={dashboardDelete} alt="" />
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                        ) : (
                                            <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<div className='tooltip-custom'>{t("download")}</div>}
                                                >
                                                    {trialDaysLeft <= 0 ? (
                                                        <div className='dashboard-options-icon-wrapper disabled'>
                                                            <img src={dashboardDownload} alt="" />
                                                        </div>
                                                    ) : (
                                                        <div onClick={() => downloadCode(code)} className='dashboard-options-icon-wrapper'>
                                                            <img src={dashboardDownload} alt="" />
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<div className='tooltip-custom'>{t("edit")}</div>}
                                                >
                                                    {trialDaysLeft <= 0 ? (
                                                        <div className='dashboard-options-icon-wrapper disabled'>
                                                            <img src={dashboardEditPencil} alt="" />
                                                        </div>
                                                    ) : (
                                                        <div onClick={() => navigate(`/dashboard/edit/${code.id}`)} className='dashboard-options-icon-wrapper'>
                                                            <img src={dashboardEditPencil} alt="" />
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                                {isMobile ? (
                                                    <div onClick={() => handleOpenMobileOptions(code)} className='dashboard-options-icon-wrapper'>
                                                        <img src={dashboardMoreOptions} alt="" />
                                                    </div>
                                                ) : (
                                                    <Dropdown onToggle={handleToggleDropdown}>
                                                        <Dropdown.Toggle disabled={trialDaysLeft <= 0 ? true : false} className='bootstrap-default-dropdown bootstrap-transparent-dropdown dropdown-disabled' id="dropdown-basic">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={!hideOptions ? <div className='tooltip-custom'>{t("options")}</div> : <></>}
                                                            >
                                                                <div className='dashboard-options-icon-wrapper'>
                                                                    <img src={dashboardMoreOptions} alt="" />
                                                                </div>
                                                            </OverlayTrigger>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className='dashboard-options-menu-block'>
                                                            {!code.data.paused && code.type === 'dynamic' && (
                                                                <Dropdown.Item onClick={() => handleArchiveDynamicCode(code)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                                    <div className='dashboard-more-options-item-wrapper'>
                                                                        {code.archived ? t("unpauseTracking") : t("pauseTracking")}
                                                                        <img src={dashboardPause} alt="" />
                                                                    </div>
                                                                </Dropdown.Item>
                                                            )}
                                                            <Dropdown.Item onClick={() => handleDeleteCode(code.id)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                                <div className='dashboard-more-options-item-wrapper'>
                                                                    {t("deleteCode")}
                                                                    <img src={dashboardDelete} alt="" />
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </>
            ) : (
                <div onClick={(e) => handleOpenOverviewPanel(e, code)} className="dashboard-grid-view-element">
                    <div className='dashboard-grid-view-top-block'>
                        <img className='dashboard-grid-view-top-static-image' src={code.image} alt="" />
                    </div>
                    <div className='dashboard-grid-view-bottom-block'>
                        <div className='dashboard-grid-view-bottom-left-block'>
                            <h5 className='flex items-center'>
                                <span style={{ paddingRight: '8px' }} className='dashboard-grid-view-bottom-left-name'>
                                    {code.name}
                                </span>
                                {!code.archived && !code.data.paused && trialDaysLeft > 0 && (
                                    <div className='dashboard-active-dynamic-code-green-circle-wrapper'>
                                        <span className='dashboard-active-dynamic-code-green-circle'></span>
                                    </div>
                                )}
                            </h5>
                            <div className='dashboard-grid-view-bottom-left-types'>
                                <>
                                    {trialDaysLeft > 0 ? (
                                        <>
                                            {code.type === 'dynamic' ? (
                                                <div className='dashboard-grid-view-content-type-dynamic-block'>{t("dynamic")}</div>
                                            ) : (
                                                <div className='dashboard-grid-view-code-type-block'>{t("static")}</div>
                                            )}
                                            <div className='dashboard-grid-view-code-type-block'>
                                                <img className='dashboard-grid-view-code-type-icon' width={12} src={dynamicBlockIcons[code.data.kind]} alt="" />
                                                {t(`${USER_QR_TYPE_TRANSLATIONS[CODE_TYPE_TO_NAME[code.data.kind]]}`)}
                                            </div>
                                        </>
                                    ) : (
                                        <div className='dashboard-view-grid-code-expired capitalize'>{t("expired")}</div>
                                    )}
                                </>
                            </div>
                        </div>
                        <div className='dashboard-grid-view-bottom-right-block'>
                            {trialDaysLeft > 0 ? (
                                <>
                                    {code.type === 'static' && !code.archived && (
                                        <div onClick={() => handleConvertData(code)} className='dashboard-grid-view-blue-button'>{t("convert")}</div>
                                    )}
                                    {code.data.paused && (
                                        <div onClick={() => handleArchiveCode(code)} className='dashboard-grid-view-blue-button'>{t("resume")}</div>
                                    )}
                                </>
                            ) : (
                                <div onClick={handleNavigateToUpgrade} className='dashboard-grid-view-button-gradient'>{t("upgrade")}</div>
                            )}
                            {isMobile ? (
                                <div onClick={() => handleOpenMobileOptions(code)} className='dashboard-options-icon-wrapper'>
                                    <img src={dashboardMoreOptions} alt="" />
                                </div>
                            ) : (
                                <Dropdown onToggle={handleToggleDropdown}>
                                    <Dropdown.Toggle disabled={trialDaysLeft <= 0 ? true : false} className='bootstrap-default-dropdown bootstrap-transparent-dropdown dropdown-disabled code-block-options-dropdown' id="dropdown-basic">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={!hideOptions ? <div className='tooltip-custom'>{t("options")}</div> : <></>}
                                        >
                                            <div className='dashboard-options-icon-wrapper'>
                                                <img src={dashboardMoreOptions} alt="" />
                                            </div>
                                        </OverlayTrigger>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dashboard-options-menu-block'>
                                        {code.type === 'dynamic' && (
                                            <Dropdown.Item
                                                onClick={() => navigate(`/dashboard/edit/${code.id}`)}
                                                style={{ borderRadius: '8px' }}
                                                className='bootstrap-default-item bootstrap-bold-item p-0'
                                            >
                                                <div className='dashboard-more-options-item-wrapper'>
                                                    {t("edit")}
                                                    <HiOutlinePencil size={20} color={"#334155"} />
                                                </div>
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item onClick={() => downloadCode(code)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                            <div className='dashboard-more-options-item-wrapper'>
                                                {t("download")}
                                                <img width={16} className='code-block-icon-black' src={dashboardNavigationDownload} alt="" />
                                            </div>
                                        </Dropdown.Item>
                                        {!code.data.paused && code.type === 'dynamic' && (
                                            <Dropdown.Item onClick={() => handleArchiveCode(code)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                                <div className='dashboard-more-options-item-wrapper'>
                                                    {code.archived ? t("unpauseTracking") : t("pauseTracking")}
                                                    <img src={dashboardPause} alt="" />
                                                </div>
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item onClick={() => handleDeleteCode(code.id)} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                            <div className='dashboard-more-options-item-wrapper'>
                                                {t("deleteCode")}
                                                <img src={dashboardDelete} alt="" />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div >
            )}
            {
                selectedCodeOptions && (
                    <div className='link-filter-background'>
                        <div ref={mobileCodeOptionsRef} style={{ minHeight: 'unset' }} className='link-filter-block'>
                            {showMobileArchiveConfirm || showMobileDeleteConfirm ? (
                                <>
                                    <div onClick={() => { setSelectedCodeOptions(null); setShowMobileArchiveConfirm(false); setShowMobileDeleteConfirm(false) }} className='mobile-tray-horizontal-bar'>
                                        <div className='mobile-tray-horizontal-bar-element'></div>
                                    </div>
                                    {showMobileArchiveConfirm ? (
                                        <div className='mobile-confirm-tray'>
                                            <img className='confirm-modal-icon' src={confirmArchive} alt="" />
                                            <h5 className='confrim-modal-title'>
                                                {t("areYouSureYouWantTo")} {(archiveId?.type === 'dynamic' && archiveId?.data?.paused) || (archiveId?.type === 'static' && archiveId?.archived) ? t("unpause") : t("pause")} {t("tracking")}?
                                            </h5>
                                            <div style={{ flexDirection: 'column' }} className='confirm-modal-actions-block'>
                                                <button
                                                    onClick={() => { handleArchiveCodeConfirmed(); setShowMobileArchiveConfirm(false); setSelectedCodeOptions(null) }}
                                                    className='confirm-modal-action-confirm'
                                                >
                                                    {(archiveId?.type === 'dynamic' && archiveId?.data?.paused) || (archiveId?.type === 'static' && archiveId?.archived) ? t("unpauseTracking") : t("pauseTracking")}
                                                </button>
                                                <button onClick={() => setShowMobileArchiveConfirm(false)} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='mobile-confirm-tray'>
                                            <img className='confirm-modal-icon' src={confirmDelete} alt="" />
                                            <h5 className='confrim-modal-title'>{t("areYouSureYouWantToDeleteThisQRCode")}</h5>
                                            <div style={{ flexDirection: 'column' }} className='confirm-modal-actions-block'>
                                                <button
                                                    onClick={() => { handleDeleteCodeConfirmed(); setShowMobileDeleteConfirm(false); setSelectedCodeOptions(null) }}
                                                    className='confirm-modal-action-delete'
                                                >
                                                    {t("deleteQRCode")}
                                                </button>
                                                <button onClick={() => setShowMobileDeleteConfirm(false)} className='confirm-modal-action-cancel'>{t("cancel")}</button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div onClick={() => { setSelectedCodeOptions(null) }} className='mobile-tray-horizontal-bar'>
                                        <div className='mobile-tray-horizontal-bar-element'></div>
                                    </div>
                                    <div className='mobile-filter-expand-block-header'>
                                        <span style={{ color: '#141315' }} className='mobile-filter-expand-block-title'>{t("options")}</span>
                                    </div>
                                    <span className='link-filter-title-underline'></span>
                                    {selectedCodeOptions.type === 'dynamic' && (
                                        <button onClick={() => { setShowMobileArchiveConfirm(true); setArchiveId(code) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                            <div className='dashboard-more-options-item-wrapper'>
                                                <img src={dashboardPause} alt="" />
                                                {(selectedCodeOptions?.type === 'dynamic' && selectedCodeOptions?.data?.paused) || (selectedCodeOptions?.type === 'static' && selectedCodeOptions?.archived) ? t("unpauseTracking") : t("pauseTracking")}
                                            </div>
                                        </button>
                                    )}
                                    <button onClick={() => { setShowMobileDeleteConfirm(true); setDeleteId(code?.id) }} style={{ borderRadius: '8px' }} className='bootstrap-default-item bootstrap-bold-item p-0'>
                                        <div style={{ borderBottom: '0px' }} className='dashboard-more-options-item-wrapper'>
                                            <img src={dashboardDelete} alt="" />
                                            {t("deleteCode")}
                                        </div>
                                    </button>
                                </>
                            )}
                        </div>
                    </div >
                )
            }
        </>
    )
}

export default CodeBlock