import { IoClose } from 'react-icons/io5'
import { BiArchiveIn } from 'react-icons/bi'
import { IoMdClose } from 'react-icons/io'
import { HiOutlineBars3 } from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { MdOutlineQrCode } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import { useGetUserNotificationsQuery, useSendEmailSendgridAfterOpenCheckoutAndLeaveMutation } from '../../api/api'
import { GUIDES_NAVIGATION_TRANSLATIONS } from '../../helpers/translation-constants'
import { useUserCodeCreationContext } from '../../context/UserQrCreationContext'
import { useCustomNavigate } from '../../hooks/useCustomNavigate'
import { trialPeriodEndDate } from '../../helpers/functions'
import { selectUser } from '../../redux/appSlice'
import { useAuthContext } from '../../context/AuthContext'
import useGenerateUrl from '../../hooks/useGenerateUrl'
import { useViewport } from '../../hooks/useViewport'
import usePlanName from '../../hooks/usePlanName'
import i18n from '../../i18n/config'

import NotificationsPanel from '../NotificationsPanel/NotificationsPanel'
import OnboardingModal from '../OnboardingModal/OnboardingModal'
import GlobalSearch from '../GlobalSearch/GlobalSearch'

import headerNotificationIconSvg from '../../assets/icons/header-notification-icon.svg'
import NotificationsActiveIcons from '../../assets/icons/notifications/active-icon.svg'
import navigationControlIcon from '../../assets/icons/navigation-control-icon.svg'
import CheckoutHeaderStripe from '../../assets/icons/checkout-header-stripe.svg'
import mobileExpandNavSvg from '../../assets/icons/mobile-expand-nav.svg'
import upgradeBackNewSvg from '../../assets/icons/upgrade-back-new.svg'
import defaultAvatarJpg from '../../assets/images/default-avatar.webp'
import newLogoWhitePng from '../../assets/images/new-logo-white.png'
import upgradeBackSvg from '../../assets/icons/upgrade-back.svg'
import questionMark from '../../assets/icons/question-mark.svg'
import CheckoutBack from '../../assets/icons/checkout-back.svg'
import SimpleLogo from '../../assets/icons/simple-logo.svg'
import newLogo from '../../assets/icons/newlogo.svg'

const Header = () => {
    const user = useSelector(selectUser)
    const settings = useSelector((state) => state.app.settings)
    const subscription = useSelector((state) => state.app.subscription)
    const [showMenu, setShowMenu] = useState(false)
    const [isUpgrade, setIsUpgrade] = useState(false)
    const [trialDaysLeft, setTrialDaysLeft] = useState(7)
    const [showMobileDropdown, setShowMobileDropdown] = useState(false)
    const [showBusinessPages, setShowBusinessPages] = useState(false)
    const [isGenerateHomePage, setIsGenerateHomePage] = useState(false)
    const [hideBack, setHideBack] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [upgradePlanNew, setUpgradePlanNew] = useState(false)
    const [upgradePlanCheckout, setUpgradePlanCheckout] = useState(false)
    const [isDashboard, setIsDashboard] = useState(true)
    const [bussinessPages, setBussinessPages] = useState(false)
    const [showHeaderSignUp, setShowHeaderSignUp] = useState(false)
    const [showOnboardingModal, setShowOnboardingModal] = useState(false)
    const [openNotificationsPanel, setOpenNotificationsPanel] = useState(false)
    const [isUpgradeCloseIcon, setIsUpgradeCloseIcon] = useState(false)
    const [isChargePage, setIsChargePage] = useState(false)

    const { isMobile } = useViewport()

    const mobileDropdownRef = useRef(null)
    const notificationsPanelRef = useRef(null)
    const openNotificationsPanelButtonRef = useRef(null)

    let location = useLocation();
    const navigate = useCustomNavigate()
    const generateUrl = useGenerateUrl()
    const { t } = useTranslation()

    const { activeTab, setActiveTab, mobileCreationStep, setMobileCreationStep, resetStyles } = useUserCodeCreationContext()
    const { expandNavigation, setExpandNavigation } = useAuthContext()
    const { planName } = usePlanName(subscription)

    const { data: notifications } = useGetUserNotificationsQuery(true, { skip: !user || !settings || !settings?.scheduled_notifications })
    const [sendEmailAfterLeaveCheckout] = useSendEmailSendgridAfterOpenCheckoutAndLeaveMutation()

    const isUnreadNotifications = useMemo(() => {
        if (!notifications || notifications?.length === 0) return false

        return notifications?.some((el) => !el.read)
    }, [notifications])

    const handleNavigateToUpgrade = () => {
        if (subscription?.new_flow) {
            navigate('/upgrade-plan-new')
        } else {
            navigate('/upgrade-plan')
        }
    }

    const handleUpgradeBack = () => {
        if (location.pathname.includes('checkout')) {
            if (subscription?.new_flow) {
                navigate(`/upgrade-plan-new?email=true${searchParams.get('isNewUser') === 'true' ? '&icon=close' : ''}`)
            } else {
                navigate('/upgrade-plan?email=true')
            }
        } else if (location.pathname.includes('terms-of-service') || location.pathname.includes('privacy-policy') || location.pathname.includes('contact-us')) {
            navigate(-1)
        } else {
            if (user) {
                if (searchParams.get('email')) {
                    sendEmailAfterLeaveCheckout()
                    setSearchParams({})
                }

                if (searchParams.get('icon') === 'close') {
                    window.gtag('event', 'leave_checkout_after_sign_up', {
                        user_email: user.email,
                    });
                }
                navigate('/dashboard')
            } else {
                navigate(-1)
            }
        }
    }

    const handleSignUp = async () => {
        navigate('/sign-up?upgrade=true')
        window.gtag('event', 'land_on_sign_up_page', {
            type: 'sign_up_button_click',
        })
        setShowMenu(false)
    }

    const handleSignIn = async () => {
        navigate('/sign-in')
        setShowMenu(false)
    }

    const handleCancelCreation = () => {
        navigate('/generator')
        setMobileCreationStep(1)
        resetStyles()
    }

    const handleCreationBack = () => {
        setMobileCreationStep((prev) => {
            return prev - 1
        })
    }

    const handleChangeNavigationExpand = () => {
        if (!window.location.pathname.includes('upgrade-plan')) {
            setExpandNavigation((prev) => {
                return !prev
            })
        }
    }

    const handleNavigateToGuides = () => {
        window.location.href = `${window.location.origin}${GUIDES_NAVIGATION_TRANSLATIONS[i18n.language] ?? '/guides'}`
    }

    useEffect(() => {
        const isBack = searchParams.get('back')
        const icon = searchParams.get('icon')
        const isNewUser = searchParams.get('isNewUser')

        if (((icon && icon === 'close') || (isNewUser && isNewUser === 'true')) && !isMobile) {
            setIsUpgradeCloseIcon(true)
        } else {
            setIsUpgradeCloseIcon(false)
        }

        if (isBack === 'no') {
            setHideBack(false)
            setSearchParams({})
        }
    }, [searchParams, setSearchParams, isMobile])

    useEffect(() => {
        if ((location.pathname.includes('dashboard') || location.pathname.includes('settings') || location.pathname.includes('overview-code') || (location.pathname.includes('upgrade-plan') && searchParams.get('icon') !== 'close' && searchParams.get('isNewUser') !== 'true' && !isMobile)) && user) {
            setIsDashboard(true)
        } else {
            setIsDashboard(false)
        }

        if (location.pathname.includes('upgrade-plan') && (searchParams.get('icon') === 'close' || isMobile || searchParams.get('isNewUser') === 'true')) {
            setIsUpgrade(true)
        } else {
            setIsUpgrade(false)
        }

        if (!user && !location.pathname.includes('sign-up') && !location.pathname.includes('sign-in') && !location.pathname.includes('privacy-policy') && !location.pathname.includes('terms-of-service') && !location.pathname.includes('contact-us')) {
            setShowHeaderSignUp(true)
        } else {
            setShowHeaderSignUp(false)
        }

        if (location.pathname.includes('terms-of-service') || location.pathname.includes('privacy-policy') || location.pathname.includes('contact-us') || location.pathname.includes('charge')) {
            setBussinessPages(true)
        } else {
            setBussinessPages(false)
        }

        if (!location.pathname.includes('/generator') && !location.pathname.includes('/generate-code')) {
            setShowBusinessPages(true)
        } else {
            setShowBusinessPages(false)
        }

        if (location.pathname.includes('generate-code') && isMobile) {
            setIsGenerateHomePage(true)
        } else {
            setIsGenerateHomePage(false)
        }

        if (location.pathname.includes('checkout')) {
            setHideBack(false)
        }

        if (location.pathname.includes('upgrade-plan-new')) {
            setUpgradePlanNew(true)
        } else {
            setUpgradePlanNew(false)
        }

        if (location.pathname.includes('upgrade-plan/checkout')) {
            setUpgradePlanNew(true)
            setUpgradePlanCheckout(true)
        } else {
            setUpgradePlanCheckout(false)
        }

        if (location.pathname.includes('charge')) {
            setIsChargePage(true)
        } else {
            setIsChargePage(false)
        }
    }, [location, isMobile, user, searchParams])

    useEffect(() => {
        if (user) {
            setShowMenu(false)
        }
    }, [user])

    useEffect(() => {
        if (location.pathname.includes('settings') && isMobile) {
            setExpandNavigation(false)
        }
    }, [isMobile, location, setExpandNavigation])

    useEffect(() => {
        if (subscription) {
            if (subscription && subscription?.plan === 'Free') {
                const result = trialPeriodEndDate(subscription.trial_end)

                setTrialDaysLeft(result)
            }
        }
    }, [subscription])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target)) {
                setShowMobileDropdown(false);
            }

            if (notificationsPanelRef.current && !notificationsPanelRef.current.contains(event.target) && openNotificationsPanelButtonRef.current && !openNotificationsPanelButtonRef.current.contains(event.target)) {
                setOpenNotificationsPanel(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    return (
        <div className={`header-wrapper ${upgradePlanNew && user ? 'header-gray-background' : ''} ${upgradePlanNew && isMobile ? 'sticky-header-upgrade' : ''} ${(isDashboard || bussinessPages || isUpgrade) && user ? 'new-dashboard-header-wrapper' : ''} ${isChargePage ? 'border-0' : ''}`}>
            <div className='flex w-full justify-between'>
                {(isUpgrade || bussinessPages) && user ? (
                    <>
                        {hideBack || isUpgradeCloseIcon ? (
                            <div></div>
                        ) : (
                            <div onClick={handleUpgradeBack} className='flex items-center cursor-pointer'>
                                <div className='header-upgrade-back-new-button'>
                                    <img src={
                                        isMobile && !upgradePlanCheckout && searchParams.get('icon') === 'close'
                                            ? SimpleLogo
                                            : isMobile && (upgradePlanCheckout || searchParams.get('icon')) !== 'close' ?
                                                CheckoutBack
                                                : upgradeBackNewSvg
                                    } alt="" />
                                </div>
                            </div>
                        )}
                        <div onClick={handleUpgradeBack} className='flex items-center cursor-pointer me-4 md:me-5'>
                            {upgradePlanNew && isMobile ? (
                                <span className='header-checkout-pages-title'>
                                    {upgradePlanCheckout ?
                                        <div className="flex items-center gap-[16px]">
                                            {t("secureCheckout")}
                                            {isMobile && (
                                                <img src={CheckoutHeaderStripe} alt="" />
                                            )}
                                        </div>
                                        : t("selectAPlan")
                                    }
                                </span>
                            ) : (
                                <>
                                    <img width={25} src={newLogoWhitePng} alt="" />
                                    <span style={{ color: 'white' }} className='header-logo-text'>QR Code Developer</span>
                                </>
                            )}
                        </div>
                        <div></div>
                    </>
                ) : isGenerateHomePage ? (
                    <>
                        {mobileCreationStep === 1 ? (
                            <>
                                <Link
                                    className='flex items-center me-2 md:me-5'
                                    to={user ? generateUrl('/dashboard') : generateUrl('/generator')}
                                >
                                    <img src={newLogo} alt="" />
                                    <span className='header-logo-text'>QR Code Developer</span>
                                </Link>
                                <IoClose onClick={handleCancelCreation} size={30} fill="#334155" />
                            </>
                        ) : (
                            <>
                                <div onClick={handleCreationBack} className='flex items-center cursor-pointer'>
                                    <img src={upgradeBackSvg} alt="" />
                                </div>
                                <Link
                                    className='flex items-center me-2 md:me-5'
                                    to={user ? generateUrl('/dashboard') : generateUrl('/generator')}
                                >
                                    <img src={newLogo} alt="" />
                                    <span className='header-logo-text'>QR Code Developer</span>
                                </Link>
                                <div></div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className='flex items-center justify-between gap-2'>
                            {user && (
                                <div onClick={handleChangeNavigationExpand} className={`${isMobile && expandNavigation ? 'navigation-control-icon-wrapper' : ''} navigation-control-icon-block`}>
                                    {isMobile && expandNavigation ? (
                                        <img src={mobileExpandNavSvg} className='navigation-control-icon' alt="" />
                                    ) : (
                                        <img
                                            className='navigation-control-icon'
                                            src={navigationControlIcon}
                                            alt=""
                                        />
                                    )}
                                </div>
                            )}
                            {isDashboard ? (
                                <Link
                                    className={user && isMobile ? 'user-header-logo-block-width-centered' : 'user-header-logo-block-width'}
                                    to={user ? generateUrl('/dashboard') : location.pathname.includes('/generate-code') || location.pathname.includes('/generator') ? generateUrl('/generator') : generateUrl('/')}
                                >
                                    <img width={25} src={newLogoWhitePng} alt="" />
                                    {(!isMobile || (isMobile && !user)) && (
                                        <span style={{ color: 'white' }} className='header-logo-text'>QR Code Developer</span>
                                    )}
                                </Link>
                            ) : (
                                <Link
                                    className={user && isMobile ? 'user-header-logo-block-width-centered' : 'user-header-logo-block-width'}
                                    to={user ? generateUrl('/dashboard') : location.pathname.includes('/generate-code') || location.pathname.includes('/generator') ? generateUrl('/generator') : generateUrl('/')}
                                >
                                    <img src={newLogo} alt="" />
                                    {(!isMobile || (isMobile && !user)) && (
                                        <span className='header-logo-text'>QR Code Developer</span>
                                    )}
                                </Link>
                            )}
                            {!user && showBusinessPages && (
                                <div className='header-busines-pages-navigation'>
                                    <Link className='header-nav-link' to={generateUrl('/contact-us')}>
                                        {t("contactUs")}
                                    </Link>
                                    <Link className='header-nav-link' to={generateUrl('/terms-of-service')}>
                                        {t("termsOfService")}
                                    </Link>
                                    <Link className='header-nav-link' to={generateUrl('/privacy-policy')}>
                                        {t("privacyPolicy")}
                                    </Link>
                                    <div onClick={handleNavigateToGuides} className='header-nav-link cursor-pointer'>
                                        {t("guides")}
                                    </div>
                                </div>
                            )}
                        </div>
                        {user ? (
                            <>
                                <div className={`flex justify-center items-center gap-4`}>
                                    <GlobalSearch
                                        setShowOnboardingModal={setShowOnboardingModal}
                                        setShowNotificationsPanel={setOpenNotificationsPanel}
                                        isUnreadNotifications={isUnreadNotifications}
                                        settings={settings}
                                    />
                                </div>
                                {!isMobile && isDashboard && (
                                    <div className='header-notification-block-wrapper gap-1'>
                                        <div className='header-notifications-block' onClick={() => setShowOnboardingModal(true)}>
                                            <img src={questionMark} alt="" />
                                        </div>
                                        <div
                                            ref={openNotificationsPanelButtonRef}
                                            onClick={() => setOpenNotificationsPanel(!openNotificationsPanel)}
                                            className={`header-notifications-block ${openNotificationsPanel ? 'opened' : ''}`}
                                        >
                                            {((settings?.pending_notifications && !settings?.scheduled_notifications) ||
                                                (settings?.scheduled_notifications && isUnreadNotifications)) && (
                                                    <div className='header-notifications-active'></div>
                                                )}
                                            {openNotificationsPanel ? (
                                                <img src={NotificationsActiveIcons} alt="" />
                                            ) : (
                                                <img src={headerNotificationIconSvg} alt="" />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className='header-unauthorized-desktop'>
                                    <button onClick={handleSignIn} className='sign-in-header-button me-0'>{t("login")}</button>
                                    <button onClick={handleSignUp} className='header-sign-up-btn'>
                                        {t("createAnAccount")}
                                    </button>
                                </div>
                                <div className='header-unauthorized-mobile'>
                                    {showHeaderSignUp && (
                                        <button onClick={handleSignUp} className='header-mobile-sign-up-button'>{t("signUp")}</button>
                                    )}
                                    {showMenu ? (
                                        <IoMdClose onClick={() => setShowMenu(false)} fill='#ffffff' size={30} />
                                    ) : (
                                        <HiOutlineBars3 onClick={() => setShowMenu(true)} fill='#334155' size={30} />
                                    )}
                                </div>
                            </>
                        )}
                        {showMenu && (
                            <>
                                {
                                    user ? (
                                        <div className='header-menu-mobile-signedin'>
                                            <div className='header-menu-close-header'>
                                                <Link
                                                    className='flex items-center'
                                                    to={user ? generateUrl('/dashboard') : generateUrl('/')}
                                                >
                                                    <img src={newLogo} alt="" />
                                                    <span className='header-logo-text'>QR Code Developer</span>
                                                </Link>
                                                <IoMdClose onClick={() => setShowMenu(false)} fill='#334155' size={30} />
                                            </div>
                                            <div className='user-dashboard-navigation-mobile'>
                                                <div className='navigation-items-block'>
                                                    <div onClick={() => { setActiveTab('My Codes'); setShowMenu(false) }} className={activeTab === 'My Codes' ? 'navigation-item-block-active' : 'navigation-item-block'}>
                                                        <MdOutlineQrCode fill={activeTab === 'My Codes' ? '#9747FF' : '#7D8898'} size={25} />
                                                        <span className='navigation-item-text'>My Codes</span>
                                                    </div>
                                                    <div onClick={() => { setActiveTab('Archived'); setShowMenu(false) }} className={activeTab === 'Archived' ? 'navigation-item-block-active' : 'navigation-item-block'}>
                                                        <BiArchiveIn fill={activeTab === 'Archived' ? '#9747FF' : '#7D8898'} size={25} />
                                                        <span className='navigation-item-text'>Archived</span>
                                                    </div>
                                                </div>
                                                <div className='navigation-footer-block w-full'>
                                                    <div onClick={() => setShowMobileDropdown(true)} className={subscription?.plan === 'Free' ? 'nav-footer-user' : 'nav-footer-user mb-0'}>
                                                        <div className='nav-footer-user-personal'>
                                                            <img className='footer-user-personal-image' src={user?.photoURL ? user?.photoURL : defaultAvatarJpg} alt="" />
                                                            <span className='footer-user-personal-name'>{user?.displayName}</span>
                                                        </div>
                                                        <div className='nav-footer-user-plan'>{subscription?.plan === 'Free' ? 'Free Trial' : `${planName}`}</div>
                                                    </div>
                                                    {subscription?.plan === 'Free' && (
                                                        <>
                                                            <div className='nav-footer-line'></div>
                                                            {trialDaysLeft >= 1 ? (
                                                                <span className='nav-footer-trial-ends'>Your free trial ends in {trialDaysLeft === 1 ? '1 day.' : `${trialDaysLeft} days.`}</span>
                                                            ) : (
                                                                <span className='nav-footer-trial-ends'>Your free trial expired.</span>
                                                            )}
                                                            <div className='w-full flex items-center justify-center'>
                                                                <button onClick={handleNavigateToUpgrade} className='nav-footer-upgrade'>Upgrade</button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div >
                                    ) : (
                                        <div className='header-menu-mobile'>
                                            <div className='header-menu-close-header'>
                                                <div className='flex gap-2 items-center'>
                                                    <img width={25} src={newLogoWhitePng} alt="" />
                                                    <span className='header-menu-header-logo-text'>QR Code Developer</span>
                                                </div>
                                                <IoMdClose onClick={() => setShowMenu(false)} fill='#ffffff' size={30} />
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='header-signup-white-text'>{t("createAnAccount")}</span>
                                                <span className='header-signup-blue-text mt-0'>{t("toDownloadMoreQRCodes")}</span>
                                            </div>
                                            <div className='header-menu-mobile-buttons'>
                                                <button onClick={handleSignUp} style={{ border: '1px solid #E2E8F0' }} className='header-google-sign-up-btn flex justify-center mt-10'>
                                                    {t("createAnAccount")}
                                                </button>
                                                <button onClick={handleSignIn} className='header-mobile-menu-signin-btn'>{t("logIn")}</button>
                                                <div onClick={() => setShowMenu(false)} className='header-busines-pages-navigation-mobile'>
                                                    <Link className='header-nav-link' to={generateUrl('/contact-us')}>
                                                        {t("contactUs")}
                                                    </Link>
                                                    <Link className='header-nav-link' to={generateUrl('/terms-of-service')}>
                                                        {t("termsOfService")}
                                                    </Link>
                                                    <Link className='header-nav-link' to={generateUrl('/privacy-policy')}>
                                                        {t("privacyPolicy")}
                                                    </Link>
                                                    <div onClick={handleNavigateToGuides} className='header-nav-link cursor-pointer'>
                                                        {t("guides")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </>
                        )}
                    </>
                )}
            </div >
            {
                showOnboardingModal && (
                    <OnboardingModal
                        show={showOnboardingModal}
                        onClose={() => setShowOnboardingModal(false)}
                    />
                )
            }
            {
                openNotificationsPanel && (
                    <NotificationsPanel
                        blockRef={notificationsPanelRef}
                        setOpenNotificationsPanel={setOpenNotificationsPanel}
                    />
                )
            }
        </div >
    )
}

export default Header