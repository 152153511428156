import React from 'react'
import { useTranslation } from 'react-i18next'

const Mp3Preview = ({ data }) => {
    const { t } = useTranslation()

    const onClickRedirect = (link) => {
        const editedLink = link.includes('https://') || link.includes('http://') ? link : `https://${link}`
        const linkWithoutSpaces = editedLink.replaceAll(' ', '')

        window.open(linkWithoutSpaces, '_blank')
    }

    return (
        <div className='mp3-preview-wrapper' style={{ backgroundColor: data.designSecondaryColor }}>
            <div className='mp3-preview-upper-block' style={{ backgroundColor: data.designPrimaryColor }}>
                <h5 className='mp3-preview-title' style={{ color: data.designTextColor }}>{data.title ? data.title : t("audioTitle")}</h5>
                <span className='mp3-preview-description' style={{ color: data.designTextColor }}>{data.description ? data.description : t("description")}</span>
                <button onClick={() => onClickRedirect(data.redirect)} className='mp3-preview-button' style={{ backgroundColor: data.designButtonColor }}>{data.button ? data.button : 'CTA'}</button>
            </div>
            <div className='mp3-preview-lower-block' style={{ backgroundColor: data.designSecondaryColor }}>
                <div className='mp3-image-block'>
                    {data.logo && (
                        <img className='mp3-logo' src={typeof (data.logo) === 'string' ? data.logo : URL.createObjectURL(data.logo)} alt="" />
                    )}
                </div>
                <audio controlsList={data.allowDownload ? "" : "nodownload"} className='mp3-audio' src={typeof (data.file) === 'string' ? data.file : URL.createObjectURL(data.file)} controls></audio>
            </div>
        </div>
    )
}

export default Mp3Preview