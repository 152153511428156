import React, { useEffect, useRef, useState } from 'react'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useEditQrCodeContext } from '../../../context/EditQrCodeContext';
import { useViewport } from '../../../hooks/useViewport';
import { isValidURL } from '../../../helpers/functions';

import CreationContentTitle from '../../common/CreationContentTitle/CreationContentTitle';

import activeFilterSvg from '../../../assets/icons/activefilter.svg'

const EditWebsiteUrl = ({ triggerValidation }) => {
    const [error, setError] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [selectUrlType, setSelectUrlType] = useState('https://')
    const [showMobileFilter, setShowMobileFilter] = useState(false)
    const [linkValue, setLinkValue] = useState('')
    const [firstLoad, setFirstLoad] = useState(true)
    const { isMobile } = useViewport()

    const typeFilterRef = useRef()

    const { t } = useTranslation()

    const {
        dynamicWebsiteStyles, setDynamicWebsiteStyles
    } = useEditQrCodeContext()

    const { register, getValues, trigger, setFocus, setValue, formState: { errors } } = useForm({
        mode: 'onBlur',
    });

    const handleChangeFilterType = async (url) => {
        const value = `${url}${getValues('link')}`

        setSelectUrlType(url)
        setShowMobileFilter(false)

        if (getValues('link')) {
            setDynamicWebsiteStyles({
                ...dynamicWebsiteStyles,
                website: value,
            })
        } else {
            setDynamicWebsiteStyles({
                ...dynamicWebsiteStyles,
                website: url,
            })
        }
    }

    const handleChangeUrl = async () => {
        const value = `${selectUrlType}${getValues('link')}`

        setLinkValue(value)
    }

    useEffect(() => {
        if (dynamicWebsiteStyles) {
            setValue('link', dynamicWebsiteStyles.website.split('://')[1])
            setSelectUrlType(dynamicWebsiteStyles.website.split('://')[0] + '://')
            setFocus('link')
        }
    }, [dynamicWebsiteStyles])

    useEffect(() => {
        if (triggerValidation) {
            const triggerFunction = async () => {
                let result = false

                const value = `${selectUrlType}${getValues('link')}`

                if (isValidURL(value)) {
                    result = true
                }

                return result
            }

            triggerValidation(triggerFunction)
        }
    }, [triggerValidation, trigger])

    useEffect(() => {
        let timer;

        if (!firstLoad) {
            if (getValues('link').includes('https://') || getValues('link').includes('http://')) {
                const validValue = getValues('link').replace('https://', '').replace('http://', '')

                setValue('link', validValue)

                setLinkValue(`${selectUrlType}${validValue}`)
            }

            if (isValidURL(linkValue)) {
                setError(false)
                setIsVerified(true)
                setDynamicWebsiteStyles({
                    ...dynamicWebsiteStyles,
                    website: linkValue,
                })
            } else if (isVerified) {
                setError(true)
                setIsVerified(false)
            }

            timer = setTimeout(() => {
                if (isValidURL(linkValue)) {
                    setError(false)
                    setIsVerified(true)
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: linkValue,
                    })
                } else if (!getValues('link')) {
                    setError(false)
                    setIsVerified(false)
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: `${selectUrlType}`,
                    })
                } else {
                    setError(true)
                    setIsVerified(false)
                    setDynamicWebsiteStyles({
                        ...dynamicWebsiteStyles,
                        website: linkValue,
                    })
                }
            }, 1500)
        } else {
            setFirstLoad(false)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [linkValue])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (typeFilterRef.current && !typeFilterRef.current.contains(event.target)) {
                setShowMobileFilter(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <form onSubmit={(e) => e.preventDefault()} className='content-creation-form-container gap-0'>
            <div className='flex flex-col gap-y-6'>
                {!isMobile && (
                    <CreationContentTitle title={"URL"} withStep={false} />
                )}
                <div className='content-creation-input-wrapper'>
                    <label className='content-creation-input-label'>{t("enterUrl")}</label>
                    <div className={(error || errors.link) ? 'url-input-wrapper-block url-input-wrapper-block-red-border' : isVerified ? 'url-input-wrapper-block url-input-wrapper-block-verified' : 'url-input-wrapper-block'}>
                        {isMobile ? (
                            <div onClick={() => setShowMobileFilter(true)} className='dropdown-label-wrapper-url-type'>
                                <MdKeyboardArrowDown size={28} fill="#334155" />
                                {selectUrlType}
                            </div>
                        ) : (
                            <Dropdown>
                                <Dropdown.Toggle className='dropdown-label-wrapper-url-type' id="dropdown-basic">
                                    <MdKeyboardArrowDown size={28} fill="#334155" />
                                    {selectUrlType}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className='bootstrap-default-item' onClick={() => handleChangeFilterType('https://')}>
                                        https://
                                    </Dropdown.Item>
                                    <Dropdown.Item className='bootstrap-default-item' onClick={() => handleChangeFilterType('http://')}>
                                        http://
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <input
                            className='form-input-dashboard creation-link-input border-radius-left-none'
                            style={{ height: '24px' }}
                            placeholder={t("enterWebsiteUrl")}
                            {...register("link", {
                                onChange: () => handleChangeUrl(),
                                value: dynamicWebsiteStyles.website
                            })}
                        />
                        {isVerified && <BsFillCheckCircleFill className='icon-input-validation-passed' size={20} fill="#9747FF" />}
                    </div>
                    {(error || errors.link) && <p className='error-text'>{t("enterAValidUrl")}</p>}
                </div>
            </div>
            {showMobileFilter && (
                <div className='link-filter-background'>
                    <div ref={typeFilterRef} className='link-filter-block'>
                        <span className='link-filter-block-title'>{t("websiteTypeLowercase")}</span>
                        <span className='link-filter-title-underline'></span>
                        <div onClick={() => handleChangeFilterType('https://')} className='link-filter-item'>
                            <span className={selectUrlType === 'https://' ? 'link-filter-active-item' : 'link-filter-default-item'}>https://</span>
                            {selectUrlType === 'https://' && (
                                <img src={activeFilterSvg} alt="" />
                            )}
                        </div>
                        <div onClick={() => handleChangeFilterType('http://')} className='link-filter-item'>
                            <span className={selectUrlType === 'http://' ? 'link-filter-active-item' : 'link-filter-default-item'}>http://</span>
                            {selectUrlType === 'http://' && (
                                <img src={activeFilterSvg} alt="" />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </form>
    )
}

export default EditWebsiteUrl