import { useState } from "react";
import { useSelector } from "react-redux";

import { useDeleteStaticCodeMutation, useHandleArchiveDynamicCodeMutation, useHandleArchiveStaticCodeMutation, useSendEmailSendgridAfterDownloadMutation } from "../api/api";
import { useCustomNavigate } from "./useCustomNavigate";
import { useViewport } from "./useViewport";
import useToasts from "./useToasts";

const useDashboardActions = () => {
    const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false)
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const [overviewCode, setOverviewCode] = useState(null)
    const [archiveId, setArchiveId] = useState(null)
    const [deleteId, setDeleteId] = useState(null)

    const user = useSelector((state) => state.app.user)

    const navigate = useCustomNavigate()

    const { isMobile } = useViewport()
    const { downloadedToast, archiveToast, pauseToast, deleteToast } = useToasts()

    const [sendDownloadEmail] = useSendEmailSendgridAfterDownloadMutation()
    const [archiveDynamicCode] = useHandleArchiveDynamicCodeMutation()
    const [archiveStaticCode] = useHandleArchiveStaticCodeMutation()
    const [deleteCode] = useDeleteStaticCodeMutation()

    const handleEditQrCode = async (id) => {
        navigate(`/dashboard/edit/${id}`)
    }

    const handleArchiveDynamicCode = (code) => {
        setShowArchiveConfirmModal(true)
        setArchiveId(code)
    }

    const handleDeleteCode = (id) => {
        setShowDeleteConfirmModal(true)
        setDeleteId(id)
    }

    const handleArchiveCode = (code) => {
        setShowArchiveConfirmModal(true)
        setArchiveId(code)
    }

    const handleArchiveCodeConfirmed = () => {
        if (archiveId.type === 'static') {
            const updateCode = { ...archiveId, archived: !archiveId.archived }
            archiveStaticCode(updateCode).then(() => {
                setShowArchiveConfirmModal(false)
                setArchiveId(null)
                archiveToast(!archiveId.archived)
            })
        } else {
            const updateCode = { ...archiveId, data: { ...archiveId.data, paused: !archiveId.data.paused } }
            archiveDynamicCode(updateCode).then(() => {
                setShowArchiveConfirmModal(false)
                setArchiveId(null)
                pauseToast(!archiveId.data.paused)
            })
        }

        if (isMobile) {
            setOverviewCode(null)
        }
    }

    const handleDeleteCodeConfirmed = () => {
        deleteCode(deleteId).then(() => {
            setShowDeleteConfirmModal(false)
            setDeleteId(null)
            deleteToast()
        })

        if (isMobile) {
            setOverviewCode(null)
        }
    }

    const downloadCode = async (code, isMultiple = false) => {
        try {
            const response = await fetch(code.image);
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'qrcode.jpeg';
            link.click();

            window.URL.revokeObjectURL(url);

            window.gtag('event', 'qrcode_download', {
                user_id: user?.uid,
                qr_code_id: code?.id,
            })

            sendDownloadEmail(code?.id)

            if (!isMultiple) {
                downloadedToast(isMobile)
            }
        } catch (error) {
            window.open(code.image)
        }
    }

    return {
        downloadCode,
        handleEditQrCode,
        handleArchiveDynamicCode,
        handleDeleteCode,
        showArchiveConfirmModal,
        showDeleteConfirmModal,
        archiveId,
        deleteId,
        setShowArchiveConfirmModal,
        setShowDeleteConfirmModal,
        setArchiveId,
        setDeleteId,
        handleArchiveCode,
        handleArchiveCodeConfirmed,
        handleDeleteCodeConfirmed,
        overviewCode,
        setOverviewCode,
    }
}

export default useDashboardActions